import { Vue, Component, Prop } from 'vue-property-decorator';
import { IHeader } from './../models/IHeader';

@Component({
  name: 'Header'
})
export default class MobileHeader extends Vue {
    public hoverText: string = '';
    public firstNameTooltip: boolean = false;

    @Prop()
    headerInput!: IHeader;

    get headerProps() {
      return this.filterHeader();
    }

    selected(index: number) {
      this.headerInput.navView.forEach((el, elIndex) => {
        el.isSelected = false;
        if((elIndex == index) && el.hasOwnProperty('openStatus')) {
          el.openStatus = !el.openStatus;
        }
        else if(el.hasOwnProperty('openStatus')) {
          el.openStatus = false;
        }
        el.nestedDrop && el.nestedDrop.forEach((nestEl) => {
          if (nestEl.hasOwnProperty('isSelected')) {
            nestEl.isSelected = false;
            if(nestEl.hasOwnProperty('openStatus')) {
              nestEl.openStatus = false;
            }
          }
        });
      });
      this.headerInput.navView[index].isSelected = true;
    }

    dropSelection(dropIndex: number) {
      this.headerInput.navView.forEach((el) => {
        el.nestedDrop && el.nestedDrop.forEach((nestEl, nestElIndex) => {
          if((nestElIndex == dropIndex) && nestEl.hasOwnProperty('isSelected')) {
            nestEl.isSelected = true;
            if(nestEl.hasOwnProperty('openStatus')) {
              nestEl.openStatus = !nestEl.openStatus;
            }
          }
          else if (nestEl.hasOwnProperty('isSelected')) {
            nestEl.isSelected = false;
            if(nestEl.hasOwnProperty('openStatus')) {
              nestEl.openStatus = false;
            }
          }
        });
      });
    }


    emitRoute(route: string) {
      if(route) {
        this.$emit('visitRoute', `${route}`);
      }
    }

    emitRedirectRoute(route: string) {
      if(route) {
        this.$emit('redirectRoute', `${route}`);
      }
    }

    emitEvent(callback: string, item: object) {
      this.$emit('emitEvent', {callEvent:callback, data: item});
      if(callback==='siteVisit') {
        this.close();
      }
    }

    visitRoute(index: number, route: string) {
      this.selected(index);
      this.onValidRoutePush(route);
    }

    visitRedirectRoute(index: number, route: string) {
      this.selected(index);
      this.emitRedirectRoute(route);
    }

    visitNestedRoute(index: number, route: string) {
      this.dropSelection(index);
      this.onValidRoutePush(route);
    }

    redirectRoute(index: number, route: string) {
      this.dropSelection(index);
      this.emitRedirectRoute(route);
    }

    filterHeader() {
      let headerInput: IHeader;
      if(this.headerInput && this.headerInput.navView && (this.headerInput.navView.length>0) && this.headerInput.roleType) {
        headerInput = JSON.parse(JSON.stringify(this.headerInput));
        for(const header of headerInput.navView) {
          if(header.profile) {
            header.nestedDrop = header.nestedDrop && header.nestedDrop.filter((item) => {
              return !item.removeForMobile;
            });
            break;
          }
        }
      }
      else {
        headerInput = {} as IHeader;
      }
      return headerInput;
    }

    closeDropdowns() {
      const currentRoute = this.$router.currentRoute.path;
      this.headerInput.navView.forEach((el) => {
        if(el.hasOwnProperty('openStatus') && el.openStatus && el.nestedDrop && !el.nestedDrop.some((nestedEl) => {
          return (nestedEl.route === currentRoute);
        })) {
          el.openStatus = false;   
          el.nestedDrop && el.nestedDrop.forEach((nestEl) => {
            if(nestEl.hasOwnProperty('openStatus') && nestEl.openStatus) {
              nestEl.openStatus = false;
            }
          }); 
        }
      });
    }

    onValidRoutePush(route: string) {
      const currentRoute = this.$router.currentRoute.path;
      if(currentRoute !== route) {
        this.emitRoute(route);
      }
      this.close();
    }

    close() {
      this.closeDropdowns();
      this.$emit('close');
    }
    callHover(hoverText: string, hoverId: string, length: number) {
      if (hoverText !== 'N/A' && hoverText !== '' ) {
        if(hoverId=='profile'){
          this.hoverText = hoverText;
          const userInformation=<HTMLSpanElement>document.querySelector('.ellipsis');
          const computedStyle=window && window.getComputedStyle(userInformation);
          const nameWidth=computedStyle.width || '';
          const nameWidthInSpliced=nameWidth.slice(0,6);
          if(nameWidthInSpliced && parseFloat(nameWidthInSpliced)>179.14){
            this.firstNameTooltip=true;
          }
        }
        else if(hoverText.length>length){
          this.hoverText=hoverText;
        }
      }
    }
    
    callHoverOut() {
      this.hoverText = '';
    }
    touchStart(val:string, id:string, length: number) {
      return () => {
        this.callHover(val, id, length);
      };
    }
    touchEnd(){
      return () => {
        this.callHoverOut();
      };
    }
}